<template>
<section class="hero is-small is-primary">
    <div class="hero-body" v-if="hero.titre">
        <p class="title" v-if="hero.titre != 'plouf-plouf'">
        {{ hero.titre }}
        </p>
        <div class="is-flex is-justify-content-center" v-else>
          <div class="animate__animated" :class="{'animate__fadeIn': TirageTitreAnimés === -2}"><p class="title is-size-1 has-text-centered ">Plouf,&nbsp;</p></div>
          <div class="animate__animated" :class="{'animate__fadeIn': TirageTitreAnimés === -1}"><p class="title is-size-1 has-text-centered ">Plouf !</p></div>
        </div>
        <p class="subtitle" v-if="hero.soustitre">
        {{ hero.soustitre }}
        </p>

    </div>
</section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'hero',
    props: ["hero"],
    computed: {
    ...mapState(['TirageTitreAnimés']),
    },
}
</script>

<style scoped>
.title {
    font-family: 'gluten';
}
.animate__animated.animate__pulse {
  --animate-duration: 0.5s;
}
</style>