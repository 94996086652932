import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import _ from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-vue';
// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
        dark: {
          dark: false,
          colors: {
            primary: "#7957d5",
          },
        }
    }
  }
})

// Depuis https://github.com/Traliant/buefy-vue3
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import 'animate.css';

createApp(App).use(store).
               use(router)
               .use(Buefy)
               .use(VueAxios, axios)
               .use( CKEditor )
               .use(vuetify)
               .mount('#app')