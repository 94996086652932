<template>
  <router-view />
</template>

<script>
import { mapActions, mapState, mapMutations} from 'vuex';
import SauvegardeCours from "@/js/SauvegardeCours"
export default {
  name: 'HomePage',
  data() {
    return {
    };
  },
  mounted() {
    // On récupère la config le plus tôt possible, entre autre pour le menu
    this.syncConfiguration({configuration: null,  sens:"Sync", $buefy: this.$buefy})
    .then( () => 
    {
      switch (this.typeConfiguration.type)
      {
        case 'dropbox':
          let sauvegarde = new SauvegardeCours( {cours: null, $buefy: this.$buefy, typeConfiguration: this.typeConfiguration})
          sauvegarde.SyncRésumés_dropbox()
          .then( () => this.updateListeCours())
          break
      }

    })
  },
  unmounted() {
  },
  computed: {
    ...mapState(['typeConfiguration'])
  },

  components: {
  },
  methods: {
    ...mapActions(['syncConfiguration']),
    ...mapMutations(['updateListeCours'])
  },
};
</script>