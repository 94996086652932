<template>
    <b-modal v-model="afficher" @click="afficher=false">
            <p class="image is-1by1 image_full_screen">
                <img :src="mon_image">
            </p>
        </b-modal>
</template>

<script>
export default {
  props: ['src'],
  name: 'MontreMoiUneImage',
   data() {
     return {
        afficher: false,
        mon_image: null,
     };
   },
    watch: {
        src(newval, oldval) {
          console.log(newval)
            if (newval)
            {
              this.mon_image = newval
              this.afficher = true
              this.$emit('image_affichée')
            }
        }
    },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
  },
  components: {
  },
  methods: {
  }
   
}

</script>

<style scoped>

p.image_full_screen {
  max-height: 90vh;
}
</style>