import { createStore } from 'vuex'
import FdcConfig from '@/js/FdcConfig';
import ConfigLectureSeule from '../assets/ConfigLectureSeule.json'
import { toRaw } from 'vue';
import Dropbox from '@/js/Dropbox';
import tri_classes from '@/js/tri_classes';

export default createStore({
  state: {
    // Objet ma config qui gère toute la configuration
    maconfig: null,
    // configuration pour pousser une nouvelle configuration
    configuration: {},
    // contenu de la configuration accessible de façon réactive
    liens: [],
    établissements: {},
    classes : {},
    classes_ordonnées: [],
    commandes: [],
    remplacements: [],
    caractères: [],
    auteur: "",
    police: "",
    horloge: "",
    editerCommeNouveau: null,
    NouvelleVisite: true,

    typeConfiguration: "",
    typehorloge: "",
    listecours: true,
    éditeurs: [],
    indexÉditeurEnCours: -1,
    commandeediteur: null,
    TirageTitreAnimés: null,
    maj_listecours: null,
    last_erreur: false,

    photos: [],
    chargementPhotos: false,
  },
  getters: {
  },
  mutations: {
    updateNouvelleVisite(state) {
      this.state.NouvelleVisite = false;
    },
    
    updateTypehorloge(state, newValue) {
      state.typehorloge = newValue;
    },
    updateListeCours(state) {
      state.maj_listecours = Date.now();
    },
    // newValue : index de l'éditeur OU '' si c'est un nouveau
    updateindexÉditeurEnCours(state, newValue) {
      if (newValue !== '')
        state.indexÉditeurEnCours = newValue;
      else
        state.indexÉditeurEnCours = state.éditeurs.length - 1
    },
    updateéditeurs(state, newValue) {
      if (newValue.éditeur === null)
      {
        state.éditeurs.splice(newValue.id, 1)
      }
      else if (newValue.id === '')
        state.éditeurs.push(newValue.éditeur)
      else
        state.éditeurs[newValue.id] = newValue.éditeur;
    },
    updateCommandeEditeur(state, newValue) {
      state.commandeediteur = newValue;
    },
    updateTirageTitreAnimés(state, newValue) {
      state.TirageTitreAnimés = newValue;
    },
    updateMaConfig(state, newValue) {
      state.maconfig = newValue;
    },
    updateErreur(state, newValue) {
      state.erreur = newValue;
    },
    updateEditerCommeNouveau(state, newValue) {
      state.editerCommeNouveau = newValue;
    },
    razConfig(state) {
      state.maconfig = null
    }
    
  },
  actions: {
    /*
    * parametres:
    * {
    *  configuration: null | {}
    *  sens: "EcraseLeStore" pour écraser le store, "Sync" pour les deux sens, "EcrasePartagé" pour écraser config enregistrée dossier/dropbox/navigateur
    */
    syncConfiguration({state}, parametres) {
      // On synchronise
      return new Promise( (resolve, reject) => {
          if (state.maconfig == null)
          {
            state.maconfig = new FdcConfig(parametres.$buefy);
            state.typeConfiguration = state.maconfig.getTypeStockage();
            state.maconfig.sync(null, state.configuration, "EcraseLeStore")
              .then((config) => {
                resolve(config)})
              .catch( (erreur) => reject(erreur) )
          }
          else
          {
            state.maconfig.sync(parametres.configuration == null ? state.configuration:parametres.configuration, state.configuration, parametres.sens)
              .then( (config) => resolve(config) )
              .catch( (erreur) => reject(erreur) )
          }
        })
      // On copie tous les objets
      .then( (nouvelleConfiguration) => {
        if (!nouvelleConfiguration)
          return Promise.reject("La configuration est vide !")

        state.typeConfiguration = state.maconfig.getTypeStockage();
        state.configuration = _.cloneDeep(nouvelleConfiguration)
        state.liens = _.cloneDeep(state.configuration?.liens || [])
        state.établissements = _.cloneDeep(state.configuration?.établissements || {})
        state.classes = _.cloneDeep(state.configuration?.classes || {})
        state.classes_ordonnées = tri_classes.tri(state.configuration)
        state.commandes = _.concat(ConfigLectureSeule.commandes, state.configuration?.editeur?.commandes.map(e => toRaw(e)))
        state.remplacements = _.cloneDeep(state.configuration?.editeur?.remplacements || [])
        state.caractères = _.cloneDeep(state.configuration?.editeur?.caractères || [])
        state.auteur = _.cloneDeep(state.configuration?.editeur?.auteur || "")
        state.police = _.cloneDeep(state.configuration?.editeur?.police || "")
        state.horloge = state.configuration?.editeur?.horloge || false
        if (parametres.message_ok && state.typeConfiguration.type !== 'temporaire')
        {
          parametres.$buefy.snackbar.open({
            duration: 5000,
            message: `${parametres.message_ok}`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'ok',
            queue: false
         })
        }
        state.last_erreur = false
      })
      // Erreurs
      .catch( (erreur) => {
        if (!state.last_erreur)
        {
          parametres.$buefy.snackbar.open({
                duration: 10000,
                message: `Une erreur est survenue lors de la synchronisation de la configuration : ${erreur}`,
                type: 'is-danger',
                position: 'is-top',
                actionText: 'ok',
                queue: false
            })
          }
        state.last_erreur = true
      })
    },
    RécupèreDernièresPhotos({state}) {
        state.chargementPhotos = true
        let dbx = new Dropbox()
        let listeNomsTéléchargés = []
        return dbx.getFolder("/photos")
        .then ( photos => {
            let listeNoms = photos.map( p => p.nom)
            let Promesses = []
            for (let photo of photos)
            {
              if (!listeNoms.includes(photo.name))
              {
                listeNomsTéléchargés.push(photo.name)
                Promesses.push(dbx.getFileBinary(photo.path_lower))
              }
            }
            return Promise.all(Promesses)
        })
        .then( photos => {
            for (let index in photos.reverse().slice(0,5))
            {
              state.photos[index]={nom: listeNomsTéléchargés[index], data:`data:image/jpeg;base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(photos[index])))}`}
              state.photos = state.photos
            }
            if (photos.length < 5)
              return Promise.resolve([])
            return Promise.resolve(listeNomsTéléchargés.slice(0,photos.length-5))
        })
        .then( (listeàsupprimer) => {
          if (listeàsupprimer.length == 0)
            return Promise.resolve()
          return dbx.deleteFiles(listeàsupprimer.map(nom => {return { path: "/photos/" + nom}}))
        })
        .finally( () => state.chargementPhotos = false)
    },
  },
  modules: {
  }
})
