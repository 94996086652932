export default class tri_classes {
    // Prend l'objet config qui a config.classes { uuid1:{}, uuid2:{}}
    // et tri les classes par établissemnt puis par 1ere lettre inversée puis par le reste dans le bon ordre
    static tri (config) {
        return Object.values(config.classes).sort((a,b) => {
            // d'abord l'établissement
            if (config.établissements[a.établissement].nom != config.établissements[b.établissement].nom) {
                return config.établissements[a.établissement].nom > config.établissements[b.établissement].nom ? 1 : -1
            }
            // Puis la 1ere lettre de la classe décroissant (6e avant 3e) si c'est un nombre
            if (!isNaN(parseFloat(a.nom[0])) && !isNaN(parseFloat(b.nom[0])) && a.nom[0] !== b.nom[0]) {
                return a.nom[0] > b.nom[0] ? -1:1
            }
            // Pour le reste, l'ordre alphabétique
            return a.nom > b.nom ? 1 : -1
        })
    }
}