<template>
    <b-modal
            v-model="afficher"
            has-modal-card
            trap-focus
            destroy-on-hide
            aria-role="dialog"
            aria-label="Example Modal"
            close-button-aria-label="Fermer"
            aria-modal>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head" v-if="cours_complet?.titre">
                <p class="modal-card-title">{{ cours_complet?.titre }}</p>
                <button
                    type="button"
                    class="delete"
                    @click="afficher = false"/>
            </header>
            <section class="modal-card-body">
              <article class="message is-danger" v-if="erreur">
                <div class="message-header">
                  <p>{{ erreur.titre}}</p>
                </div>
                <div class="message-body" v-html="erreur.message" />
              </article>
              <article class="message is-primary" v-if="cours_complet?.devoirs?.length">
                <div class="message-header">
                  <p>Devoirs</p>
                </div>
                <div class="message-body">
                  <article v-for="devoir in cours_complet.devoirs" class="message is-info">
                    <div class="message-header">
                      <p>Pour le {{ format_date(devoir.date) }}</p>
                      <div>
                        <b-icon title="Copier au format texte" icon="clipboard-text" @click="copie(devoir.contenu)" />
                      </div>
                    </div>
                    <div class="message-body">
                          <div v-html="devoir.contenu"></div>
                    </div>
                  </article>
                </div>
              </article>
              <article class="message"  :class="{'is-primary':!erreur, 'is-warning':erreur}" v-if="cours_complet?.contenu">
                <div class="message-header">
                  <p>{{erreur? "Informations en mémoire - ":""}}Contenu de la séance</p>
                  <div>
                    <b-icon title="Copier « Contenu de la séance en pièce jointe »" icon="clipboard-text" @click="copie('Contenu de la séance en pièce jointe')" />
                  </div>
                </div>
                <div id="editor" class="message-body" v-html="cours_complet?.contenu" />
              </article>
              <article class="message is-primary" v-if="cours_complet?.pieces_jointes?.length">
                <div class="message-header">
                  <p>Pièces jointes</p>
                </div>
                <div class="message-body">
                 À faire
                </div>
              </article>
                
            </section>
            <footer class="modal-card-foot">
              <b-button
                    label="Supprimer"
                    type="is-danger"
                    @click="Supprimer" />
              <b-button
                    label="Éditer comme nouveau"
                    type="is-primary"
                    @click="Nouveau"
                    v-if="! cours_complet?.autosave" />
              <b-button
                    :label="cours_complet?.autosave?'Reprendre l\'édition':'Modifier'"
                    type="is-primary"
                    @click="Modifier" />
              <b-button
                    label="Télécharger"
                    type="is-primary"
                    @click="Télécharger"
                    v-if="! cours_complet?.autosave" />
            </footer>
         </div>
    </b-modal>
</template>

<script>
import { format } from 'date-fns';
import { fr } from "date-fns/locale";
import { mapState, mapMutations, mapGetters } from 'vuex';
import useClipboard from 'vue-clipboard3'
import SauvegardeCours from '@/js/SauvegardeCours';
import PouchDB from 'pouchdb-browser';

export default {
  props: ['cours'],
  name: 'MontreMoiUnCours',
   data() {
     return {
        cours_complet: null,
        cours_rev: null,
        afficher: false,
        erreur: null,
     };
   },
    watch: {
        cours(newval, oldval) {
            if (newval && ! newval?.autosave)
            {
              this.afficher = true
              this.erreur = null
              this.maconfig.RécupèreLeCours(newval).then( doc => {
                this.cours_rev = 
                this.cours_complet = _.merge({}, newval, doc) 
                this.cours_complet.rev_résumé = newval._rev
                this.cours_complet.revcours_complet = doc._rev
              })
              
              .catch((erreur) => {
                this.erreur = {titre: `Une erreur est survenue`, message: `Contenu incomplet. ${erreur}.<br />Il n'y a que le texte sans mise en forme ni pièces jointes.`}
                this.cours_complet = _.merge({_id: newval._id, titre: newval.titre, pieces_jointes: [], contenu: newval.contenu, devoirs: []})
              })
              .finally( () => {
                  this.$emit('cours_affiché')
              })
            }
            // Affiche un autosave
            else if (newval)
            {
              this.afficher = true
              this.erreur = null
              this.cours_complet = _.cloneDeep(newval)
            }
        }
    },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
    ...mapState(['maconfig','éditeurs','configuration'])
  },
  components: {
  },
  methods: {
    ...mapMutations(['updateListeCours','updateCommandeEditeur','updateEditerCommeNouveau']),
    Télécharger() {
      this.$emit('chargement', {icone: 'cloud-arrow-down', tourne: false, texte: 'Téléchargement des données', annulable: true})
      let sauvegarde = new SauvegardeCours({cours: this.cours_complet, $buefy: this.$buefy, typeConfiguration: "Télécharge"})
      sauvegarde.sauvegarde()
      .then( () => this.$emit('chargement', false) )
    },
    Modifier() {
      let classe = _.filter(this.configuration.classes, classe => classe.nom === this.cours_complet.classe).map(classe => classe.id)[0] || ""
      let groupe = ''
      if (classe && this.cours_complet.groupe)
        groupe = _.filter(this.configuration.classes[classe].groupes, groupe => groupe.nom === this.cours_complet.groupe).map(groupe => groupe.id)[0] || ""
      let paramètres_éditeur = {};
      paramètres_éditeur.classe_id = classe;
      paramètres_éditeur.devoirs_donnés = []
      paramètres_éditeur.rappels = []
      paramètres_éditeur.groupe_id = "";
      paramètres_éditeur.dropFiles = []
      paramètres_éditeur.document_parent_id = null;
      paramètres_éditeur.cours = {};
      paramètres_éditeur.cours._id = this.cours_complet._id
      paramètres_éditeur.cours.revcours_complet = this.cours_complet.revcours_complet
      paramètres_éditeur.cours.rev_résumé = this.cours_complet.rev_résumé
      paramètres_éditeur.cours.classe = this.cours_complet.classe
      paramètres_éditeur.cours.groupe = this.cours_complet.groupe
      paramètres_éditeur.cours.établissement = this.cours_complet.établissement
      paramètres_éditeur.cours.auteur = this.auteur || "Inconnu";
      paramètres_éditeur.cours.date_creation =  this.cours_complet.date_creation
      paramètres_éditeur.cours.date_modification = (new Date()).toISOString();
      paramètres_éditeur.cours.niveau = this.cours_complet.niveau
      paramètres_éditeur.cours.année_scolaire = this.cours_complet.année_scolaire
      paramètres_éditeur.cours.type_document = this.cours_complet.type_document
      paramètres_éditeur.cours.titre = this.cours_complet.titre;
      paramètres_éditeur.cours.pieces_jointes = this.cours_complet.pieces_jointes
      paramètres_éditeur.cours.contenu = this.cours_complet.contenu
      paramètres_éditeur.cours.devoirs = this.cours_complet.devoirs
      this.afficher = false
      this.$emit('chargement', true)
      this.updateCommandeEditeur({commande: "nouveau", éditeur: paramètres_éditeur})
      this.$router.push('/cours/edition');
    },
    Nouveau() {
      let paramètres_éditeur = {};
      paramètres_éditeur.document_parent_id = this.cours_complet._id;
      paramètres_éditeur.cours = {};
      paramètres_éditeur.cours.auteur = this.auteur || "Inconnu";
      paramètres_éditeur.cours.pieces_jointes = this.cours_complet.pieces_jointes
      paramètres_éditeur.cours.contenu = this.cours_complet.contenu
      paramètres_éditeur.cours.devoirs = this.cours_complet.devoirs
      this.afficher = false
      this.updateEditerCommeNouveau(paramètres_éditeur)
      this.$router.push({ name: 'cours-editer-comme-nouveau'})
    },
    Supprimer() {
      this.afficher = false;
      this.$buefy.dialog.confirm({
                    title: 'Suppression du cours',
                    message: `${this.cours_complet.titre}<br/>Voulez-vous <b>supprimer</b> ce cours ?`,
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                      this.SupprimeLeCours(this.cours_complet)
                        .then(() => {
                        this.$buefy.snackbar.open({
                          duration: 5000,
                          message: 'Cours supprimé avec succès',
                          type: 'is-success',
                          position: 'is-top',
                          actionText: 'ok',
                          queue: false
                          });  
                          this.updateListeCours()
                        })
                        .catch(erreur => {
                          this.$buefy.snackbar.open({
                            duration: 5000,
                            message: 'Échec de la suppression : ' + erreur,
                            type: 'is-danger',
                            position: 'is-top',
                            actionText: 'ok',
                            queue: false
                            });
                            this.updateListeCours()
                          })
                    }
                })
    },
    SupprimeLeCours(cours_complet) {
      if (cours_complet?.autosave)
        {
          let db_autosave = new PouchDB("autosave")
          return db_autosave.get(cours_complet._id).then( doc => {return db_autosave.remove(doc)})
        }
      return this.maconfig.SupprimeLeCours(cours_complet)
    },
    format_date(date) {
        return format(date, "iii d LLL", { locale: fr })
    },
    async copie(html) {
      const cheerio = require('cheerio');
      let texte = cheerio.load(html).text()
      const { toClipboard } = useClipboard()
      try {
        await toClipboard(texte)
      } catch (e) {
        console.error(e)
      }
    }
  }
   
}

</script>

<style>
div#editor.message-body div.divIcon > i.fas,
div#editor.message-body div.divIcon > i.fa-solid,
div#editor.message-body div.divIcon > i.far,
div#editor.message-body div.divIcon > i.fa-regular,
div#editor.message-body div.divIcon > i.fab,
div#editor.message-body div.divIcon > i.fa-brands
{
    font-size: 50px;
    font-style: unset;
}

/*div#editor.message-body div.Boite[devoirs="true"] {
  display: none;
}*/
@import "../assets/css/cours.css";
</style>