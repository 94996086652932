import axios from "axios";

export default class Dropbox {


  client_id = "qe5ztzhidun4j3z"
  client_secret = "8pvs9a8mirzrnc3"

  constructor () {
  }

  trouveredirectURI(mobile) {
    const host = window.location.host
    const protocol = window.location.protocol
    const redir_mobile = "mobile/"
    const redir_base = `${protocol}//${host}/forge-de-cours/${mobile ? redir_mobile : ''}configuration/dropbox`
    return redir_base
  }

  OuvreURLAutorisation(mobile)
  {
    let redirectURI = this.trouveredirectURI(mobile)
    const locale = "fr"
    const url_base = "https://www.dropbox.com/oauth2/authorize"
    window.location = `${url_base}?client_id=${this.client_id}&redirect_uri=${encodeURIComponent(redirectURI)}&locale=${locale}&response_type=code&token_access_type=offline`
  }

  getAccessToken() {
    return new Promise( (resolve, reject) => 
    {
      if (localStorage.dropbox_access_token && localStorage.dropbox_access_token_expires - 60 > Date.now()/1000 )
        resolve(localStorage.dropbox_access_token)
      else
      {
        const params = new URLSearchParams({
          refresh_token: localStorage.dropbox_refresh_token,
          grant_type: 'refresh_token',
          client_id: this.client_id,
          client_secret: this.client_secret
        })
        return axios.post('https://api.dropbox.com/oauth2/token', params)
          .then( (response) => {
            return new Promise( (resolve, reject) => {
              localStorage.setItem('dropbox_access_token', response.data.access_token)
              localStorage.setItem('dropbox_access_token_expires', response.data.expires_in + Date.now()/1000)
              resolve(response.data.access_token)
            })
          })
      }
    })
  }

  getFolder(dossier) {
    return this.getAccessToken()
    .then( (token) =>
      {
        return axios.post("https://api.dropboxapi.com/2/files/list_folder", {
          "include_deleted": false,
          "include_has_explicit_shared_members": false,
          "include_media_info": false,
          "include_mounted_folders": true,
          "include_non_downloadable_files": true,
          "path": dossier,
          "recursive": false
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
      })
      .catch( (error) => {
        if (error.response.data.error.path['.tag'] == 'not_found')
          return Promise.resolve({data:{entries:[]}})
        else
          return Promise.reject(error)
      })
      .then( (response => {
        return Promise.resolve(response.data.entries)
      }))
  }

  getFileText(fichier) {
    return this.getFile(fichier, "text")
  }

  getFileJson(fichier)
  {
    return this.getFile(fichier, "json")
  }

  getFileBinary(fichier)
  {
    return this.getFile(fichier, "arraybuffer")
  }

  getFile(fichier, responsetype) {
    return this.getAccessToken()
    .then( (token) =>
    {
      return axios.post("https://content.dropboxapi.com/2/files/download", null,
      {
        responseType: responsetype,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/plain',
          'Dropbox-API-Arg': this.http_header_safe_json({path: fichier})
        }
      })
    }).then( (response => {
      return new Promise((resolve, reject) => {
        if (response.data)
          resolve(response.data)
        else
          reject(response.message.response.data.error_summary)
      })
    }))
  }

  saveFileTexte(fichier, contenu) {
    // From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    function bytesToBase64(bytes) {
      const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte),
      ).join("");
      return btoa(binString);
    }
    return this.saveFileBase64(fichier, bytesToBase64(new TextEncoder().encode(contenu)))
  }

  saveFileBase64(fichier, base64Data) {

    return this.getAccessToken()
    .then( (token) =>
    {
      var Buffer = require('buffer').Buffer
      const binaryData = Buffer.from(base64Data, 'base64');

      return axios.post("https://content.dropboxapi.com/2/files/upload", binaryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/octet-stream',
          'Dropbox-API-Arg': this.http_header_safe_json({autorename: false, mode: 'overwrite', mute: true, path: fichier, strict_conflict: false})
        }
      })
    })
    .catch( response => {
      return new Promise( (resolve, reject) => {
        reject(response?.response?.data?.error_summary || response)
      })
    })
  }

  /*
   fichiers : [{path: …}, {path: …}]
  */
  deleteFiles(fichiers) {
    return this.getAccessToken()
    .then( (token) =>
    {
      return axios.post("https://api.dropboxapi.com/2/files/delete_batch", {entries: fichiers},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
    })
  }

  revoke() {
      return this.getAccessToken()
      .then( (token) => axios.post("https://api.dropboxapi.com/2/auth/token/revoke", null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }))
      .then( () => {
          localStorage.removeItem('dropbox_access_token')
          localStorage.removeItem('dropbox_access_token_expires')
          localStorage.removeItem('dropbox_refresh_token')
      })
  }

  getRefreshToken(route, mobile) {
    let redirectURI = this.trouveredirectURI(mobile)
    return new Promise((resolve, reject) => {
      if (route.query.code)
        resolve(route.query.code)
      else
        reject( {message: route.query.error, response: {data: {error_description: route.query.error_description}}})
      })
    .then(code => 
        {
          const params = new URLSearchParams({
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: redirectURI,
            client_id: this.client_id, 
            client_secret: this.client_secret, 
          })
          return axios.post('https://api.dropbox.com/oauth2/token',params)
        })
      .then((response) =>
          {
            return new Promise((resolve, reject) => {
            localStorage.setItem('dropbox_access_token', response.data.access_token)
            localStorage.setItem('dropbox_access_token_expires', response.data.expires_in + Date.now()/1000)
            localStorage.setItem('dropbox_refresh_token', response.data.refresh_token)
            resolve("Connecté à Dropbox")
          })
        })
  }

  // From https://www.dropbox.com/developers/reference/json-encoding
  // This function is simple and has OK performance compared to more
  // complicated ones: http://jsperf.com/json-escape-unicode/4
  http_header_safe_json(v) {
    var charsToEncode = /[\u007f-\uffff]/g;
    return JSON.stringify(v).replace(charsToEncode,
      function(c) {
        return '\\u'+('000'+c.charCodeAt(0).toString(16)).slice(-4);
      }
    );
  }
}
