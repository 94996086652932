<template>
  <v-app full-height>
      <v-main >
        <router-view v-slot="{ Component }">
            <keep-alive>
              <component  :is="Component" />
            </keep-alive>
        </router-view>
      </v-main>
      <v-bottom-navigation
        v-model="activeIndex"
        :absolute="false"
        :value="activeIndex"
        mode="shift"
        color="primary"
        :disabled="typeConfiguration?.nuage === false"
        >
        <v-btn  size="small" v-for="(item, index) in items" :key="index" @click="navigate(index)" >
          <v-icon>{{ item.icon }}</v-icon>
          <span>{{ item.text }}</span>
        </v-btn>
      </v-bottom-navigation>
  </v-app>
</template>


<script>
import { mapState } from 'vuex';

export default {
  
  data() {
    return {
      items: [
        { icon: 'mdi-logout', text: 'Réglages', route: '/mobile/serveur', textLong: 'Forge de cours' },
        // { icon: 'mdi-file-multiple', text: 'Cours', route: '/mobile/derniers-cours', textLong: 'Les derniers cours' },
        // { icon: 'mdi-account-group', text: 'Classes', route: '/mobile/classes', textLong: 'Les classes' },
        { icon: 'mdi-calendar-check', text: 'Listes', route: '/mobile/listes', textLong: 'Aujourd\'hui' },
        { icon: 'mdi-camera', text: 'Photos', route: '/mobile/photos', textLong: 'Envoyer une photo' },
      ]
    }
  },
  mounted() {
    if (this.typeConfiguration.nuage)
      this.$router.push("/mobile/serveur")
    document.title = "Forge de cours – site mobile compagnon"
  },
  computed: {
    ...mapState(['typeConfiguration']),
    activeIndex() {
      switch (this.$route.path) {
        case "/mobile/server":
          return 0
        case "/mobile/derniers-cours":
          return 1
        case "/mobile/classes":
          return 2
        case "/mobile/listes":
          return 3
        case "/mobile/photos":
          return 4
      }
    },
    
  },
  methods: {
    navigate(index) {
      const route = this.items[index].route;
      this.$router.push(route);
    }
  }
}
</script>

<style>

</style>