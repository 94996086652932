<template>
  <b-loading is-full-page v-model="loading" :can-cancel="loading?.annulable === undefined || loading?.annulable">
    <div class="is-flex is-flex-direction-column is-align-items-center loading-info" v-if="loading.icone || loading.texte">
      <b-icon
        v-if="loading.icone"
        pack="fas"
        :icon="loading.icone"
        size="is-large"
        :custom-class="{'fa-spin':loading?.tourne}" />
      <span v-if="loading.texte">{{ loading?.texte}}</span>
    </div>
    <div class="is-flex is-flex-direction-column is-align-items-center loading-info" v-else>
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-spin" />
      <span>Préparation de la page…</span>
      
    </div>
  </b-loading>
    <Menu  ref="menu_ref"  @montre_moi_un_cours="montre_moi_un_cours" @montre_moi_une_image="montre_moi_une_image" @chargement="chargement"/>
    <div id="mon-container" @click="cacherlemenu">
      <Hero :hero="hero"/>
      <div class="p-4">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component  :is="Component"
                        @changerhero="changerhero"
                        @montre_moi_un_cours="montre_moi_un_cours"
                        @montre_moi_une_image="montre_moi_une_image"
                        @chargement="chargement"
                        />
          </keep-alive>
          <Horloge />
          <MontreMoiUnCours :cours="cours" @cours_affiché="cours_affiché"/>
          <MontreMoiUneImage :src="image" @image_affichée="image_affichée"/>
        </router-view>

      </div>
    </div>
  </template>
  <script>
  import { mapState, mapMutations } from 'vuex';
  import Menu from '@/components/Menu.vue'
  import Hero from '@/components/Hero.vue';
  import Horloge from '@/components/Horloge.vue'
  import MontreMoiUnCours from '@/components/MontreMoiUnCours.vue';
  import MontreMoiUneImage from '@/components/MontreMoiUneImage.vue';
  
  export default {
    emits: ['changerhero', 'montre_moi_un_cours', 'montre_moi_une_image', 'chargement'],
    name: 'App',
    data() {
      return {
        hero: {titre:"",soustitre:"", page:""},
        cours: null,
        image: null,
        loading: false,
      };
    },
    mounted() {
      this.cours =  null;
      if (this.horloge)
        this.updateTypehorloge({type:'Horloge',taille:'miniature'})
      else
        this.updateTypehorloge({type:''})
      window.addEventListener('beforeunload', this.handlePageUnload);
      window.addEventListener('unload', this.handlePageUnload);
     
    },
    beforeUnmount() {
      window.removeEventListener('beforeunload', this.handlePageUnload);
      window.removeEventListener('unload', this.handlePageUnload);
    },
    components: {
      Hero,
      Menu,
      Horloge,
      MontreMoiUnCours,
      MontreMoiUneImage,
    },
    computed: {
      ...mapState(['horloge','éditeurs']),
    },
    methods: {
      ...mapMutations(['updateTypehorloge']),
      changerhero(hero)
      {
        this.hero = hero;
        document.title = hero.page != '' ? hero.page : "Forge de cours";
      },
      cacherlemenu() {
        this.$refs.menu_ref.cacherlemenu()
      },
      montre_moi_un_cours(cours) {
        this.cours = cours
      },
      cours_affiché() {
        this.cours = null
      },
      montre_moi_une_image(image) {
        this.image = image
      },
      image_affichée() {
        this.image = null
      },
      handlePageUnload(e) {
        if (this.éditeurs.length)
        {
          let confirmationMessage = 'Votre travail n\'est pas enregistré.\nVoulez vous tout de même partir ?';
          e.preventDefault();
          e.returnValue = confirmationMessage
          return confirmationMessage
        }
      },
      chargement(isLoading)
      {
        this.loading = isLoading
      }
    },
  };
  </script>
  
  <style>


  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @font-face {
    font-family: "OpenDyslexic";
    src: url('../../fonts/OpenDyslexic-Regular.woff2') format('woff2'),
  }

  @font-face {
    font-family: "Gluten";
    src: url('../../fonts/Gluten-Bold.woff2') format('woff2'),
  }

  

  
  div#mon-container {
    /* largeur du menu*/
    margin-left: 40px;
    height: 100vh;
  }

  div.loading-background {
    backdrop-filter: blur(5px);
  }

  .loading-info {
    z-index:1;
  }
  
  </style>