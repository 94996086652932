import Dropbox from '@/js/Dropbox';
import PouchDB from "pouchdb-browser"
import { saveAs } from 'file-saver';
import { minify } from 'terser';
import * as csso from 'csso';
import { format } from 'date-fns';
import { de, fr } from "date-fns/locale";

const css_cours = require('!raw-loader!../assets/css/cours.css');
const css_cours_html = require('!raw-loader!../assets/css/cours_html.css');
const opendysjs = require('!raw-loader!./opendys.js');
const pjjs = require('!raw-loader!./pj.js');
const imgjs = require('!raw-loader!./img.js');


export default class SauvegardeCours {

    constructor(params) {
        this.cours = params.cours
        this.$buefy = params.$buefy
        this.typeConfiguration = params.typeConfiguration
    }


    async PageHTML(cours) {
        let pjjsMinified = ""
        let imgjsMinified = ""
        let opendysjsMinified = ""
        await Promise.all( [
            minify(pjjs.default, { compress: true, mangle: true, }),
            minify(imgjs.default, { compress: true, mangle: true, }),
            minify(opendysjs.default, { compress: true, mangle: true, }),
        ])
        .then ( result => {
            pjjsMinified = result[0]
            imgjsMinified = result[1]
            opendysjsMinified = result[2]
        })
        const cssMinified_cours = csso.minify(css_cours.default).css;
        const cssMinified_cours_html = csso.minify(css_cours_html.default).css;

return `<!DOCTYPE html>
<html lang="fr">
<head>
  <meta charset="UTF-8">
  <meta forgedecours_version="2.9">
  <meta _id="${cours._id }">
  <meta author="${cours.auteur}">
  <meta creation_timestamp="${cours.date_creation}">
  <meta modification_timestamp="${cours.date_modification}">
  <meta nom_classe="${cours.classe}">
  <meta groupe="${cours.groupe}">
  <meta annee_scolaire="${cours.année_scolaire}">
  <meta etablissement="${cours.établissement}">
  <meta type_document="${cours.type_document}">
  <meta niveau="${cours.niveau}">
  <title>${cours.titre}</title>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
  <link type="text/css" rel="stylesheet" href="https://unpkg.com/buefy/dist/buefy.min.css" />
  <link href=" https://cdn.jsdelivr.net/npm/open-dyslexic@1.0.3/open-dyslexic-regular.min.css " rel="stylesheet">
  <style>${cssMinified_cours}</style>
  <style>${cssMinified_cours_html}</style>
  <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/2.0.0/FileSaver.min.js" integrity="sha512-csNcFYJniKjJxRWRV1R7fvnXrycHP6qDR21mgz1ZP55xY5d+aHLfo9/FcGDQLfn2IfngbAHd8LdfsagcCqgTcQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script x-data="pj">
    PiecesJointes = ${JSON.stringify(cours.pieces_jointes)}
  </script>
  <script x-data="devoirs">
    Devoirs = ${JSON.stringify(cours.devoirs)}
  </script>
  <script>
    ${cours.pieces_jointes ? pjjsMinified.code : ''}
    ${imgjsMinified.code}
    ${opendysjsMinified.code}
  </script>
</head>
<body>
<div class="modal">
  <div class="modal-background"></div>
  <div class="modal-content is-flex is-justify-content-center">
    <img class="is-align-self-center" />
  </div>
</div>
<div class="contenu">
    <section class="hero is-primary is-small">
    <div class="hero-body">
    <p class="title">${format(new Date(cours.date_creation), "iiii d LLLL yyyy, H'h'mm", { locale: fr })}</p>
    <div class="is-flex is-justify-content-end is-align-items-baseline no-print">
    <label class="checkbox opendyslexic mr-2">
        <input id="opendys" type="checkbox" />
        Utiliser la police OpenDyslexic
    </label>
    <button class="button is-primary is-light" onclick="window.print()">
    <span class="icon">
      <i class="fas fa-print"></i>
    </span>
    <span>Imprimer</span>
  </button>
    </div>
    </div>
    </section>
    <div id="editor">
    ${cours.contenu}
    </div>
</div>
</body>
</html>`;
    }

    sauvegarde_fichier() {
        return this.PageHTML(this.cours).then(data => {
            let blob = new Blob([data], {type: 'text/html'});
            saveAs(blob, this.cours.titre + ".html");
            return Promise.resolve()
        })
        
    }
    /*
     Sauvegarde le cours complet dans la BDD cours_integraux à partir du contenu du fichier html
    */
    sauvegarde_navigateur_courscomplet_html(html) {
        let contenu = $(html).find("div#editor")[0].innerHTML
        let devoirs = JSON.parse($(html).filter("script[x-data='devoirs']").text().trim().split("Devoirs = ")[1])
        let pieces_jointes = JSON.parse($(html).filter("script[x-data='pj']").text().trim().split("PiecesJointes = ")[1])
        let cours_complet = {"_id": this.cours._id, "contenu": contenu, "pieces_jointes": pieces_jointes, "devoirs": devoirs, "titre":this.cours.titre}
        let cours_integraux   = new PouchDB('cours_integraux');
        return cours_integraux.put(cours_complet)
    }


    sauvegarde_navigateur(en_ligne) {
        const cheerio = require('cheerio');
        let résumé = {...this.cours}
        if (en_ligne)
            résumé.en_ligne = true
        résumé.pieces_jointes = []
        résumé.contenu = cheerio.load(this.cours.contenu).text()

        let resumes_des_cours = new PouchDB('resumes_des_cours');
        let cours_integraux   = new PouchDB('cours_integraux');
        let cours_complet = {"_id": this.cours._id, "contenu": this.cours.contenu, "pieces_jointes": this.cours?.pieces_jointes, "devoirs": this.cours?.devoirs, "titre":this.cours?.titre}
        if (this.cours.revcours_complet)
            {
                cours_complet["_rev"] = résumé.revcours_complet
                delete résumé.revcours_complet
            }
        if (this.cours.rev_résumé)
            {
                résumé["_rev"] =  this.cours.rev_résumé
                delete résumé['rev_résumé']
            }
        return Promise.all([cours_integraux.put(cours_complet), resumes_des_cours.put(résumé)] )
    }

    sauvegarde_dropbox() {
        let dbx = new Dropbox()
        return this.PageHTML(this.cours)
        .then((html_data) => {
            const cheerio = require('cheerio');
            let résumé = {...this.cours}
            résumé.pieces_jointes = []
            résumé.en_ligne = true
            résumé.contenu = cheerio.load(this.cours.contenu).text()
            if (this.cours.revcours_complet)
            {
                delete résumé.revcours_complet
            }
            if (this.cours.rev_résumé)
            {
                résumé["_rev"] =  this.cours.rev_résumé
                delete résumé['rev_résumé']
            }
            return Promise.all([
                dbx.saveFileTexte(`/cours/${this.cours._id} — ${this.cours.titre}.html`, html_data),
                dbx.saveFileTexte(`/résumés/${this.cours._id} — ${this.cours.titre}.json`, JSON.stringify(résumé))
            ])
        })
       
    }

    SyncRésumés_dropbox() {
        let dbx = new Dropbox()
        let resumes_des_cours = new PouchDB('resumes_des_cours');
        let cours_integraux   = new PouchDB('cours_integraux');
        let nombreCoursTéléchargés = 0
        let nombreCoursEnvoyés = 0
        let nombreCoursSupprimés = 0

        return Promise.all([dbx.getFolder("/résumés"), dbx.getFolder("/cours"), resumes_des_cours.allDocs({include_docs: true}), cours_integraux.allDocs({include_docs: true})])
        .then(données => {
            let listeFichiersJson = données[0]
            let listeFichiersCours = données[1]
            let listeRésumésLocal = données[2].rows
            let listeCoursIntLocal = données[3].rows

            let listeNomsFichiersJsonDropbox = listeFichiersJson.map(fichier => fichier.name)
            let listeNomsFichiersCoursDropbox = listeFichiersCours.map(fichier => fichier.name)
            let listeIdRésumésLocauxNonEnvoyés = listeRésumésLocal.filter(row => ! row.doc.en_ligne).map(row => row.doc._id)
            let listeIdCoursIntLocauxNonEnvoyés = listeCoursIntLocal.filter(row => listeIdRésumésLocauxNonEnvoyés.includes(row.doc._id)).map(row => row.doc._id)
            let listeNomsFichiersRésumésLocauxEnvoyés = listeRésumésLocal.filter(row => row.doc.en_ligne).map(row => row.doc._id + ' — ' + row.doc.titre + '.json')
            let listeNomsFichiersCoursIntLocauxEnvoyés = listeCoursIntLocal.filter(row => ! listeIdRésumésLocauxNonEnvoyés.includes(row.doc._id)).map(row => row.doc._id + ' — ' + row.doc.titre + '.html')
            let listeNomsFichiersRésumésLocaux = listeRésumésLocal.map(row => row.doc._id + ' — ' + row.doc.titre + '.json')
            let listeNomsFichiersCoursIntLocaux = listeCoursIntLocal.map(row => row.doc._id + ' — ' + row.doc.titre + '.html')

            // Info : _.différence renvoie ce qui n'est que dans 1er tableau et pas dans le 2e.

            // Tout ce qui est sur le serveur ET pas dans le navigateur est à télécharger
            let listeNomsFichiersDlJson  = _.difference(listeNomsFichiersJsonDropbox, listeNomsFichiersRésumésLocaux)
            let listeNomsFichiersDLCours = _.difference(listeNomsFichiersCoursDropbox, listeNomsFichiersCoursIntLocaux)
            // Et on ne télécharge en vrai que ce qui a un résumé ET un cours
            let listeNomsFichiersSansExtensionÀDL = _.intersection(listeNomsFichiersDlJson.map(nom => nom.split('.json')[0]), listeNomsFichiersDLCours.map(nom => nom.split('.html')[0]) )
            
            
            // Liste de ceux qui n'ont pas été envoyés et qui sont envoyable (càd résumé + cours complet)
            let listeIdÀEnvoyer = _.union(listeIdRésumésLocauxNonEnvoyés, listeIdCoursIntLocauxNonEnvoyés).map(titre => titre.split(' — ')[0])

            // les résumés qui ont déjà été envoyés sur le serveur et qui n'y sont plus car ils doivent être supprimés du navigateur et non réenvoyés.
            let listeRésumésIdASupprimer = _.difference(listeNomsFichiersRésumésLocauxEnvoyés, listeNomsFichiersJsonDropbox).map(nom => nom.split(' — ')[0])
            // les cours qui ont déjà été envoyés sur le serveur et qui n'y sont plus car ils doivent être supprimés du navigateur et non réenvoyés.
            let listeCoursIdASupprimer = _.difference(listeNomsFichiersCoursIntLocauxEnvoyés, listeNomsFichiersCoursDropbox).map(nom => nom.split(' — ')[0])

            nombreCoursTéléchargés = listeNomsFichiersSansExtensionÀDL.length
            nombreCoursEnvoyés = listeIdÀEnvoyer.length
            nombreCoursSupprimés = listeRésumésIdASupprimer.length /* car s'il n'y a que listeCoursIdASupprimer, il n'apparait de toute façon pas dans le navigateur*/

            let promessesDLJson = listeNomsFichiersSansExtensionÀDL.map(fichier => this.DlJsonDropbox(fichier+".json"))
            let promessesEnvoi = listeIdÀEnvoyer.map(id => this.UlCoursDropbox(id))
            let promessesRemoveRésumés = listeRésumésIdASupprimer.map( id => this.SupprimeRésuméBdd(id))
            let promessesRemoveCours = listeCoursIdASupprimer.map( id =>  this.SupprimeCoursBdd(id))


            return Promise.all([...promessesEnvoi, ...promessesRemoveRésumés, ...promessesRemoveCours, ...promessesDLJson])
            })
            .then( () => {
                if (nombreCoursTéléchargés + nombreCoursEnvoyés + nombreCoursSupprimés)
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: `<b>Synchronisation avec Dropbox.</b><br />${nombreCoursTéléchargés} cours téléchargé(s)<br />${nombreCoursEnvoyés} cours envoyé(s)<br />${nombreCoursSupprimés} cours supprimés(s) du navigateur.`,
                        type: 'is-primary',
                        position: 'is-top',
                        actionText: 'ok',
                        queue: false
                        })
                return Promise.resolve()
            })
            .catch( erreur => {
                this.$buefy.snackbar.open({
                        duration: 10000,
                        message: `Dropbox : Erreur survenue lors de la synchronisation des cours et résumés avec le serveur. ${erreur}`,
                        type: 'is-danger',
                        position: 'is-top',
                        actionText: 'ok',
                        queue: false
                    })
                    // On poursuit quand même ce qu'il y a à faire
                    return Promise.resolve()
                }
                
                )
    }

    DlJsonDropbox(fichier) {
        let dbx = new Dropbox()
        return dbx.getFileJson("/résumés/"+fichier)
        .then(résumé => {
            let resumes_des_cours = new PouchDB('resumes_des_cours');
            return resumes_des_cours.put(résumé)
        })
    }

    UlCoursDropbox(id) {
        let cours_integraux   = new PouchDB('cours_integraux');
        let resumes_des_cours = new PouchDB('resumes_des_cours');
        return Promise.all([resumes_des_cours.get(id), cours_integraux.get(id)])
        .then(data => {
            let cours = {...data[0], ...data[1]}
            delete cours._rev
            let svg = new SauvegardeCours({cours: cours, $buefy: this.$buefy, typeConfiguration: "dropbox"})
            return svg.sauvegarde_dropbox()
            .then( () => {
                data[0].en_ligne = true
                return resumes_des_cours.put(data[0])
            })
        })
    }

    SupprimeRésuméBdd(id) {
        let resumes_des_cours = new PouchDB('resumes_des_cours');
        resumes_des_cours.get(id)
        .then( doc => {
            return resumes_des_cours.remove(doc)
        })
    }

    SupprimeCoursBdd(id) {
        let cours_integraux   = new PouchDB('cours_integraux');
        cours_integraux.get(id)
        .then( doc => {
             return cours_integraux.remove(doc)
        })
    }

    // Les deux arguments sont optionnels
    // arg_en_ligne sert à préciser que le fichier est en ligne… Si un jour il n'y est plus, c'est qu'il a été supprimé et qu'il faudra le supprimer du navigateur
    sauvegarde(arg_type, arg_en_ligne) {
        let en_ligne = false || arg_en_ligne
        let type = arg_type || this.typeConfiguration
        switch (type)
        {
            case "dropbox":
                en_ligne = true
                return this.sauvegarde_dropbox()
                .then( () => { this.$buefy.snackbar.open({
                    duration: 5000,
                    message: 'Sauvegarde réussie',
                    type: 'is-success',
                    position: 'is-top',
                    actionText: 'ok',
                    queue: false
                    })
                })
                .catch( erreur => 
                        {
                            this.$buefy.snackbar.open({
                                duration: 5000,
                                message: `Dropbox : Erreur lors de l'enregistrement du fichier en ligne. ${erreur}`,
                                type: 'is-danger',
                                position: 'is-top',
                                actionText: 'ok',
                                queue: false
                            })
                            return this.sauvegarde_fichier()
                        }
                    )
                .then( () => this.sauvegarde("navigateur", en_ligne))
            case "navigateur":
                return this.sauvegarde_navigateur(en_ligne)
                .then( () => {
                    if (! en_ligne)
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: 'Sauvegarde réussie',
                            type: 'is-success',
                            position: 'is-top',
                            actionText: 'ok',
                            queue: false
                            })
                })
                .catch(err => {
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: 'Enregistrement dans le navigateur échoué : ' + err,
                        type: 'is-danger',
                        position: 'is-top',
                        actionText: 'ok',
                        queue: false
                    })
                    return this.sauvegarde_fichier()
                })
            case "html":
                return this.PageHTML(this.cours)
            default: // "ne pas enregistrer, télécharge, …"
                return this.sauvegarde_fichier()
                .catch(erreur => {
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: 'Sauvegarde échouée : ' + erreur,
                        type: 'is-danger',
                        position: 'is-top',
                        actionText: 'ok',
                        queue: false
                    })
                })
        }
    }
}